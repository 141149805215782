<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title">
      <template #buttons>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-table sortable responsive outlined class="mb-0"
               :items="formattedBooking"
               :fields="['Location/Property', 'customer', 'dates', 'nights', 'booking_amount', 'owner_amount', '_']">
        <template #cell(booking_amount)="row">
          <b-form-input type="number" min="0" step="0.01" v-model="row.item.booking_amount" />
        </template>
        <template #cell(owner_amount)="row">
          <b-form-input type="number" min="0" step="0.01" v-model="row.item.owner_amount" />
        </template>
        <template #cell(_)="row">
          <b-button target="_blank" :href="'/bookings/update/' + row.item.id" class="mr-1" variant="primary" size="sm">View</b-button>
          <b-button class="mr-1" variant="success" size="sm" @click="approve(row.item.booking, row.item.booking_amount, row.item?.owner_amount ?? 0)">Confirm</b-button>
          <b-button variant="danger" size="sm" @click="deleteBooking(row.item.booking)" v-b-tooltip.hover title="This action will delete the booking.">Delete</b-button>
        </template>
      </b-table>
      <b-pagination-nav class="mt-3" :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />

    </div>
  </div>
</template>

<script>
import StickyHeader from "@/components/StickyHeader.vue";
import {mapActions, mapState} from "vuex";
import moment from "moment/moment";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

export default {
  components: {StickyHeader},
  data() {
    return {
      formattedBooking: []
    };
  },
  computed: {
    ...mapState('bookings', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('bookings', ['fetch', 'updateOrCreate', 'approveBooking', 'delete']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'booking-confirmations', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'booking-confirmations', query: {...this.$route.params, page: pageNo}};
    },
    async load() {
      await this.fetch({
        page: this.$route.query.page || 1,
        perPage: 100,
        confirmed: false
      });
    },
    async approve(booking, amount, amount_client) {
      booking.guests = (booking.guests < 1) ? 1 : booking.guests
      booking.amount = amount;
      booking.amount_client = amount_client;

      await this.updateOrCreate(booking);
      await this.approveBooking(booking.id);

      await this.load();
    },
    deleteBooking(booking) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to delete this booking?", {
        title: 'Delete Booking',
        okText: 'Yes, Delete',
        okVariant: 'danger',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        return this.delete(booking.id).then(() => this.load());
      });
    }
  },
  async mounted() {
    await this.load();
  },
  watch: {
    async '$route'(newValue, value) {
      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }
    },
    data(data) {
      this.formattedBooking = [];

      let self = this;

      data.forEach(function(booking) {
        self.formattedBooking.push({
          booking: booking,
          id: booking.id,
          'Location/Property': booking?.property?.name ?? 'Unknown',
          customer: booking?.customer?.name ?? 'Unknown',
          dates : moment(booking.start_date).format('DD/MM/Y') + ' - ' + moment(booking.end_date).format('DD/MM/Y'),
          nights: Math.floor(moment(booking.end_date).diff(moment(booking.start_date), 'days', true)) + 1,
          booking_amount: booking.amount,
          owner_amount: booking.amount_client,
          '_': ''
        });
      })
    }
  }
}
</script>

<style scoped>

</style>
